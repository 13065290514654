import classNames from 'classnames'
import styles from './index.module.scss'

interface IItemProps {
  children: React.ReactNode
  className?: string
  hasLink?: boolean
  isFullscreen?: boolean
}

export function Item({
  children,
  className,
  hasLink,
  isFullscreen
}: IItemProps) {
  return (
    <div
      className={classNames(
        styles.item,
        {
          [styles.clickable]: hasLink,
          [styles.item__fullscreen]: isFullscreen
        },
        className
      )}
    >
      {children}
    </div>
  )
}
