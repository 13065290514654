import Slider, { Settings } from 'react-slick';
import classNames from 'classnames';
import { ArrowButton, ButtonPosition } from '../arrow-button';
import styles from './index.module.scss';
import { ComponentProps, MouseEventHandler, useCallback } from 'react';
import { useIntl } from 'src/domains/i18n';

interface ICarouselProps {
  children: React.ReactNode;
  slidesToShow?: {
    xs?: number;
    sm?: number;
    md?: number;
    lg?: number;
  };
  currentSlide?: number;
  slideCount?: number;
  initialSlide?: number;
  isLightbox?: boolean;
  hasButtonsInside?: boolean;
  bottomButtons?: boolean;
  settings?: Settings;
  onPrevClick?: MouseEventHandler<HTMLButtonElement>;
  onNextClick?: MouseEventHandler<HTMLButtonElement>;
}

export function Carousel({
  children,
  slidesToShow,
  isLightbox,
  initialSlide,
  hasButtonsInside,
  settings: givenSettings,
  onPrevClick,
  onNextClick,
}: ICarouselProps) {
  const { formatMessage } = useIntl();
  const isAlternative = isLightbox || hasButtonsInside;

  const NextArrowButton = useCallback(
    ({ onClick, className }: ComponentProps<'button'>) => {
      return (
        <ArrowButton
          place={ButtonPosition.AbsoluteBottom}
          isAlternative={isAlternative}
          onClick={(event) => {
            onNextClick?.(event);
            onClick?.(event);
          }}
          aria-label={formatMessage('carousel.button.next')}
          className={classNames(className, styles.arrowButton)}
        />
      );
    },
    [isAlternative, onNextClick, formatMessage],
  );

  const PrevArrowButton = useCallback(
    ({ onClick, className }: ComponentProps<'button'>) => {
      return (
        <ArrowButton
          isPrev
          place={ButtonPosition.AbsoluteBottom}
          isAlternative={isAlternative}
          onClick={(event) => {
            onPrevClick?.(event);
            onClick?.(event);
          }}
          aria-label={formatMessage('carousel.button.previous')}
          className={classNames(styles.buttons, className)}
        />
      );
    },
    [isAlternative, onPrevClick, formatMessage],
  );

  const settings = {
    accessibility: true,
    speed: 500,
    slidesToShow: slidesToShow?.lg,
    slidesToScroll: 1,
    centerMode: false,
    initialSlide: initialSlide || 0,
    draggable: false,
    infinite: false,
    responsive: [
      {
        breakpoint: 1439,
        settings: {
          slidesToShow: slidesToShow?.md,
        },
      },
      {
        breakpoint: 1023,
        settings: {
          slidesToShow: slidesToShow?.sm,
          arrows: isAlternative,
        },
      },
      {
        breakpoint: 767,
        settings: {
          slidesToShow: slidesToShow?.xs,
          centerPadding: '16px',
          centerMode: true,
          arrows: isAlternative,
          draggable: true,
        },
      },
    ],
    nextArrow: <NextArrowButton />,
    prevArrow: <PrevArrowButton />,
    ...givenSettings,
  };

  return (
    <Slider
      {...settings}
      className={classNames(styles.container, {
        [styles.container__alternative]: isLightbox,
        [styles.containerButtonsInside]: hasButtonsInside,
      })}
    >
      {children}
    </Slider>
  );
}
