import isClient from 'utils/is-client'

export function setItem (key, value) {
  if (isClient) {
    localStorage.setItem(key, value)
  }
}

export function getItem (key) {
  if (isClient) {
    return localStorage.getItem(key)
  }
}

export function setObject (key, value) {
  setItem(key, JSON.stringify(value))
}

export function getObject (key) {
  if (isClient) {
    const item = localStorage.getItem(key)
    return item && JSON.parse(item)
  }
}

export function removeItem (key) {
  if (isClient) {
    localStorage.removeItem(key)
  }
}
