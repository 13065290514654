import { ComponentProps, useEffect, useRef, useState } from 'react';
import styles from './index.module.scss';
import Icons from 'features/shared/components/icons';
import { Tooltip } from 'src/features/shared/components/tooltip/index';
import { useIntl } from 'src/domains/i18n';
import useIntersection from 'src/utils/hooks/use-intersection';
import classNames from 'classnames';

interface RatingProps extends ComponentProps<'div'> {
  animated?: boolean;
}

export function Rating({ animated = true, className, ...props }: RatingProps) {
  const { formatMessage } = useIntl();
  const ref = useRef<HTMLDivElement>(null);
  const isIntersected = useIntersection(ref);
  const [isVisible, setIsVisible] = useState(false);

  /**
   * When the element is intersected, set the state to visible
   * This will trigger the animation
   * We use this useEffect to make sure the animation is triggered only once
   */
  useEffect(() => {
    if (animated && !isVisible && isIntersected) {
      setIsVisible(true);
    }
  }, [isIntersected, animated, isVisible]);

  return (
    <div
      className={classNames(styles.container, className)}
      ref={ref}
      {...props}
    >
      <ul
        className={classNames(styles.list, {
          [styles.isVisible]: isVisible,
          [styles.animated]: animated,
        })}
      >
        <li>
          <Icons.Star className={styles.placeholder} />
          <Icons.StarFilled className={styles.filled} />
        </li>
        <li>
          <Icons.Star className={styles.placeholder} />
          <Icons.StarFilled className={styles.filled} />
        </li>
        <li>
          <Icons.Star className={styles.placeholder} />
          <Icons.StarFilled className={styles.filled} />
        </li>
        <li>
          <Icons.Star className={styles.placeholder} />
          <Icons.StarFilled className={styles.filled} />
        </li>
        <li>
          <Icons.Star className={styles.placeholder} />
          <Icons.StarHalf className={styles.filled} />
        </li>
      </ul>
      <div>
        <span>{formatMessage('rating.label')}</span>
        <Tooltip
          title={formatMessage('rating.tooltip.title')}
          description={formatMessage('rating.tooltip.description')}
          triggerClassName={styles.tooltipTrigger}
          align="center"
        >
          {formatMessage('rating.tooltip.trigger')}
        </Tooltip>
      </div>
    </div>
  );
}
